import React,{useState,useEffect} from 'react';
import Chart from '../../../components/Charts/Chart';

import _get from 'lodash/get';
const BarChart=(props)=>{
  const [data,setData]=useState([])
  // const {assetfuelGraph}=API;
        useEffect(()=>{
            // assetfuelGraph().then((res)=>setData(res.data.data))
        },[])
       const arr =['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
       let consumption=[]
       const bar = [];
       data.data && data.data.map(fuel =>{
        consumption.push(parseInt(fuel.volume_used))
         bar.push({ name: fuel.month, Consumption: parseInt(fuel.volume_used)})
       })
       const sum=[]
       const columns = []
       const excelDataOperator = [];
       data.sums && data.sums.map(total => {
         const date = new Date()
         let year = "LYTD"
         if(total.year == date.getFullYear()){
           year = "YTD"
         }
        //  sum.push({term:`${NumToStr(total.volume_used, 0)} gallons`, span:year})
       })
          let  chartData={
            labels: arr,
            datasets:
            [  
              {
                label:'Consumption',
                data:consumption,
                backgroundColor:'transparent',
                borderColor: 'rgb(38, 88, 240)',
                borderWidth: 2
              },
            ]
          }

    return(
        consumption.length!==0&&  <Chart type='bar' chartData={chartData} location="Fuel Consumption" legendPosition="bottom"/>
    )
}
export default BarChart;
